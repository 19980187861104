import React from 'react';
import './style.css';
import X1Y2Z3 from './X1Y2Z3';

function App() {
  return (
    <div className="App">
      <X1Y2Z3 />
    </div>
  );
}

export default App;

