import React from 'react';
import './style.css';

function A7B8C9() {
  return (
    <div className="a1b2c3">
      <p>
      The door was shut. I looked between
Its iron bars; and saw it lie,
My garden, mine, beneath the sky,
Pied with all flowers bedewed and green:
From bough to bough the song-birds crossed,
From flower to flower the moths and bees;
With all its nests and stately trees
It had been mine, and it was lost.
      </p>

      <p>
      A shadowless spirit kept the gate,
Blank and unchanging like the grave.
I peering through said: "Let me have
Some buds to cheer my outcast state."
He answered not. "Or give me, then,
But one small twig from shrub or tree;
And bid my home remember me
Until I come to it again."
      </p>

      <p>
      The spirit was silent; but he took
Mortal pity on my grief.
Within his hand he held a leaf:
But not by hand or lip he spoke,
And yet there ran a hollow sound
That sped me on the viewless wind,
And shook the trees, the grass, the ground:
My doom was written, I must go
And leave my garden, and shall know
No more the sweetness and the pride
I had in it before my fall.
      </p>

      <p>
      For hell is where our dreams have died,
Play me no tricks; for I have signed
The bond. How hard to leave behind
One's garden! Ah, the looks it wore
This morning, when I passed the door!
Whatever I shall do with flowers
Elsewhere, whatever talk or trade
With foreign men in foreign lands,
Shall blot the memory of the beds
I tended, or the stalks that stand
So thick and close with green and red
Surprises, or the paths I made!
      </p>

      <p>
      You might as well reproach the dead.
We follow where the vision leads;
We cry aloud for life and deed,
But dreams have laws, and these we keep,
We drowse, and dream, and have no shame
For vacant heart and hand and brain.
      </p>
    </div>
  );
}

export default A7B8C9;

